<template>

	<div class="container">
		

	


		<div class="row">

			<div class="col-md-4"></div>

			<div class="col-md-4">

					<div style="padding-top:30px; text-align: left; ">
			<h6>Check eligibility</h6>
		</div>

		<div v-if="!show_form">
			<label class="message">{{message}}</label>
			<button class="btn color-1" @click="show_form=true"> <i class="bi bi-arrow-left"></i> Check again</button>
		</div>

		
				
				<div>
			
			<form @submit.prevent="verify()" v-if="show_form">
				<div class="row">

					<div class="col-md-12 mt-3">
						<select class="form-select" v-model="season">
							<option value="">Choose election</option>
							<option v-for="season in seasons" :value="season.id"> {{season.name}} </option>
						</select>
					</div>

					<div class="col-md-12 mt-3">
						<label>Enter your KOGS number</label>
						<input type="text" placeholder="eg. KOGS000" v-model="kogs_number" class="form-control">
					</div>

					<div class="col-md-12 mt-3">
						<button class="btn btn-1">Verify</button>
					</div>

				</div>
			</form>

		</div>

			</div>

			<div class="col-md-4"></div>

		</div>



		

	</div>



	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import axios from 'axios'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	export default{
		name : 'dashboard',
		components : {Header,Footer},

		data(){
			return{
				kogs_number : '',
				seasons : [],
				season : '',
				show_form : true,
				first_name : '',
				last_name : '',
				title : ''
				
			}
		},

		methods : {

			async verify(){

				if(!this.season){
				toast.error("Choose election", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })
			return 
			}

			if(!this.kogs_number){
				toast.error("Enter KOGS number", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })
			return 
			}
			
		const res = await axios.post(this.$store.state.api_url+'api/verify-kogs-number',{
			kogs_number : this.kogs_number,
			season : this.season
		}).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		
		if(res.length>0){
			res.forEach((data)=>{
				this.first_name = data.first_name
				this.last_name = data.last_name
				this.title = data.title
			})
			this.message = 'Congratulations '+this.title+'.'+this.first_name+' '+this.last_name+'.You are in the voting register'
			this.show_form = false

		}else{
			this.message = 'You are not in the voting register'
			this.show_form = false
		}
	
			},


			async get_seasons(){
				this.show_loader = true
		const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		
		this.seasons = res
			},

		},
		created(){
			this.get_seasons()
		}
		
	}
</script>