<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:70px;">

			
			<div class="col-md-12">

				<div class="row">
					<div class="col-md-6 col-8">
						<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>
					</div>

					<div class="col-md-6 col-4" style="text-align:right;">
						<button class="btn btn-1" v-print="'#page_2'"> <i class="bi bi-printer"></i> Print</button>
					</div>
					
				</div>

		<div>
			<h5 class="color-1">Election positions</h5>
		</div>

		<form>
		<div class="row">

			<div class="col-md-4">
			<div class="shadow-lg rounded">
					<div class="form-floating">
  <select class="form-select" v-model="election" aria-label="Floating label select example" @change="get_positions()">
    <option v-for="season in seasons" :value="season.id">{{season.name}}</option>
  </select>
  <label for="floatingSelect">Select election</label>
</div>
			</div>
				</div>
		</div>

						</form>
			
			<div class="table-responsive" style="padding-top:20px;" id="page_2">
				<h6 class="color-1" v-if="positions.length>0">{{election_title}} POSITIONS</h6>
			<table class="table" v-if="positions.length>0">
				<thead>
					<tr> <th>Title</th> <th>Priority</th> <th>Description</th> <th>Election</th> <th>Action...</th> </tr>
				</thead>
				<tbody v-for="position in positions">
					<tr>
						<td> {{position.title}} </td>
						<td> {{position.priority}}</td>
						<td> {{position.description}}</td>
						<td> {{position.election_name}}</td>
						<td> <button class="btn" @click="position_details(position.id)"> <i class="bi bi-three-dots"></i> </button> </td>
					</tr>
				</tbody>
			</table>
			<div class="spinner-border color-1" role="status" v-if="show_loader">
  <span class="visually-hidden">Loading...</span>
</div>
			</div>

			</div>
			
			
		</div>

	</div>

	<Footer />




	<!-- position details -->


	<div style="position: fixed; right: 0; top: 0; width: 100%; background-color: #fff; height: 100%; z-index: 900;" class="shadow-lg rounded p-3" v-if="show_modal">

		<div class="row">
			
			<div class="col-md-6">
				

		<div style="padding-bottom:10px;" class="row">
			<div class="col-md-8 col-8">
				<h5 class="color-1">Position details</h5>
			</div>
			<div class="col-md-4 col-4" style="text-align:right;">
				<button class="btn" @click="show_modal=false"> <i class="bi bi-x-lg text-danger"></i> </button>
			</div>
		</div>

			<form @submit.prevent="edit_position()">
		<div class="row">
			<div class="col-md-12">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="election" aria-label="Floating label select example">
    <option v-for="season in seasons" :value="season.id">{{season.name}}</option>
  </select>
  <label for="floatingSelect">Select election</label>
</div>
				</div>
			<div class="col-md-6">

				<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Title" v-model="title">
  <label for="floatingInput">Title</label>
</div>
				
			</div>

			<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="number" min="1" class="form-control" placeholder="Priority" v-model="priority">
  <label for="floatingPassword">Priority</label>
</div>	
</div>


	<div class="col-md-12">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Description" v-model="description">
  <label for="floatingPassword">Description</label>
</div>	
</div>


		</div>
<div style="padding-top: 20px;">
	<button class="btn btn-2" :disabled="disabled" >{{text}}</button>
</div>
</form>


<div style="padding-top:40px;">
	<button class="btn text-danger" @click="delete_position(id)">Delete</button>
</div>	

			</div>
			
			
		</div>
	</div>




	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'
	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				positions : [],
				details : [],
				text : 'Save changes',
				disabled : false,
				title : '',
				priority : '',
				description : '',
				show_modal : false,
				id : '',
				election : '',
				seasons : [],
				show_loader : false,
				election_title : ''
			}
		},
		methods : {

				async season_details(){
				const res = await axios.get(this.$store.state.api_url+'api/season-details/'+this.election).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.election_title = data.name 
				})
	
			},

			async delete_position(id){
				const res = await axios.post(this.$store.state.api_url+'api/delete-position',{
					id : id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_positions()
				this.show_modal = false
			},


			async get_positions(){
				this.show_loader = true
		const res = await axios.get(this.$store.state.api_url+'api/get-election-positions/'+this.election).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.show_loader = false
		this.positions = res
		this.season_details()
			},

			async position_details(id){
				const res = await axios.get(this.$store.state.api_url+'api/position-details/'+id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.details = res
				res.forEach((data)=>{
					this.title = data.title
					this.priority = data.priority
					this.description = data.description
					this.id = data.id
					this.election = data.election_id
				})
				this.show_modal = true
			},

			async edit_position(){

				if(!this.title){
		toast.error("Title is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.priority){
		toast.error("Priority is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}


		if(!this.description){
		toast.error("Description is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}
				this.text = 'Please wait...'
				this.disabled = true
		const res = await axios.post(this.$store.state.api_url+'api/edit-position',{
			title : this.title,
			priority : this.priority,
			description : this.description,
			id : this.id,
			election : this.election 
		}).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})

		toast.success("Changes saved", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
	this.disabled = false
	this.text = 'Save changes'
	this.show_modal = false
	this.get_positions()

			},

			async get_seasons(){
		const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.seasons = res
			}



		},

		created(){
			this.get_seasons()
		}
	}
</script>