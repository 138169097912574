<template>
	
	<div style="text-align: center; padding-top: 50px; bottom: 0;">
		
		<div style="text-align: center;">
			<h6 class="color-1">KOGS</h6>
		<h6 style="color:grey;">Online voting management system</h6>
		</div>

	</div>

</template>