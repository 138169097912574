<template>

    <div class="container">
        <Header />

        <div class="row" style="padding-top:20px;">

        
            <div class="col-md-12">

            <div class="row">
                    <div class="col-md-6 col-8">
                        <div style="padding-bottom: 20px;">
                    <router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
                </div>
                    </div>

                  
                    
                </div>

        <div>
            <h5 class="color-1">Election results</h5>
        </div>

            <form>
        <div class="row">

            <div class="col-md-4">
                <div class="shadow-lg rounded">
                    <div class="form-floating">
  <select class="form-select" v-model="election" aria-label="Floating label select example" @change="election_positions()">
    <option v-for="season in seasons" :value="season.id">{{season.name}}</option>
  </select>
  <label for="floatingSelect">Select election</label>

</div>
                </div>
                </div>

<div class="col-md-4 col-6" style="text-align:left; padding-top:10px;">
    <button v-if="positions.length>0" type="button" class="btn color-1" @click="all_candidates()"> <i class="bi bi-arrow-clockwise"></i> Refresh </button>
</div>

  <div class="col-md-4 col-6" style="text-align:right;">
    <button type="button" v-if="positions.length>0" class="btn btn-1" v-print="'#page_2'"> <i class="bi bi-printer"></i> Print</button>
    </div>



        </div>

                        </form>


        <div style="padding-top:20px;" id="page_2">

            <h6 class="color-1">{{election_title}}</h6>

            <p>Total voters = {{total_voters}} : Those voted = {{those_voted}} : Female voters = {{female_voters}} : Male voters = {{male_voters}} </p>

            
            <div v-for="position in positions">
                <h6 class="color-1">{{position.title}}</h6>
                <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr><th>Name</th> <th>Membership number</th>  <th>Gender</th> <th>Votes</th>  </tr>
                    </thead>
                    <tbody v-for="candidate in candidates">
                        <tr v-if="position.id==candidate.position_id"> <td>{{candidate.title}} . {{candidate.first_name}} {{candidate.last_name}} </td> <td> {{candidate.membership_number}} </td>  <td> {{candidate.gender}} </td> <td> {{candidate.total_votes}} </td> </tr>
                    </tbody>
                </table>
                <div class="spinner-border color-1" role="status" v-if="show_loader">
  <span class="visually-hidden">Loading...</span>
</div>
            </div>

                <div>
                    
                

                </div>

            </div>

        </div>


            </div>
            
            
        </div>

    </div>


    
    <Footer />
    
</template>


<script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    import { toast } from 'vue3-toastify'
    import 'vue3-toastify/dist/index.css'
    import axios from 'axios'

    export default{
        name : 'dashboard',
        components : {Header,Footer},
        data (){
            return {
                seasons : [],
                election : '',
                positions : [],
                candidates : [],
                details : [],
                show_modal : false,
                position : '',
                positions_2 : [],
                id : '',
                show_loader : false,
                election_title : '',
                total_voters : 0,
                those_voted : 0,
                those_not_voted : 0,
                female_voters : 0,
                male_voters : 0
            }
        },
        methods : {

             async election_voters(){
                this.those_voted = 0
                this.those_not_voted = 0
                this.female_voters = 0
                this.male_voters = 0
                const res = await axios.get(this.$store.state.api_url+'api/election-voters/'+this.election).then(function(response){
                    return response.data
                }).catch(function(error){
                    console.log(error)
                })
            this.total_voters = res.length

            res.forEach((data)=>{
                if(data.voted==1){
                    this.those_voted++
                }

                if(data.voted==0){
                    this.those_not_voted++
                }

                if(data.gender=='Female'){
                    this.female_voters++
                }


                if(data.gender=='Male'){
                    this.male_voters++
                }
            })       
        
            },



            async season_details(){
                const res = await axios.get(this.$store.state.api_url+'api/season-details/'+this.election).then(function(response){
                    return response.data
                }).catch(function(error){
                    console.log(error)
                })
                res.forEach((data)=>{
                    this.election_title = data.name 
                })
    
            },


                //to get the position of a specific candidate
                async get_positions(){
        const res = await axios.get(this.$store.state.api_url+'api/get-positions').then(function(response){
            return response.data
        }).catch(function(error){
            console.log(error)
        })
        this.positions_2 = res
            },

            //to get all the positions
            async election_positions(){
                this.show_loader = true
        const res = await axios.get(this.$store.state.api_url+'api/election-positions/'+this.election).then(function(response){
            return response.data
        }).catch(function(error){
            console.log(error)
        })
        this.show_loader = false
        this.positions = res
        this.season_details()
        this.all_candidates()
        this.election_voters()
            },

            async get_seasons(){
        const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
            return response.data
        }).catch(function(error){
            console.log(error)
        })
        this.seasons = res
            },

                async all_candidates(){
        const res = await axios.get(this.$store.state.api_url+'api/all-candidates-results').then(function(response){
            return response.data
        }).catch(function(error){
            console.log(error)
        })
        this.candidates = res
            },

                    async candidate_details(id){
                    this.id = id
        const res = await axios.post(this.$store.state.api_url+'api/candidate-details',{
            id : id
        }).then(function(response){
            return response.data
        }).catch(function(error){
            console.log(error)
        })
        this.details = res

        res.forEach((data)=>{
            this.title = data.title
            this.position = data.position_id
            this.first_name = data.first_name
            this.last_name = data.last_name
            this.email = data.email
            this.phone = data.phone
            this.gender = data.gender
            this.membership_number = data.membership_number
        })

        this.show_modal = true
            },



        },
        created(){
            this.get_seasons()
            this.all_candidates()
            this.get_positions()
        }
    }
</script>