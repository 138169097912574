<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:20px;">

		
			<div class="col-md-12">

				<div class="row">
					<div class="col-md-6 col-8">
						<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>
					</div>

					<div class="col-md-6 col-4" style="text-align:right;">
						<button class="btn btn-1" v-print="'#page_2'"> <i class="bi bi-printer"></i> Print</button>
					</div>
					
				</div>

		<div>
			<h5 class="color-1">List voters</h5>
		</div>

			<form>
		<div class="row">

			<div class="col-md-4">
			<div class="shadow-lg rounded">
					<div class="form-floating">
  <select class="form-select" v-model="election" aria-label="Floating label select example" @change="get_voters()">
    <option v-for="season in seasons" :value="season.id">{{season.name}}</option>
  </select>
  <label for="floatingSelect">Select election</label>
</div>
			</div>
				</div>
		</div>

						</form>


		<div style="padding-top:20px;" id="page_2">
			
			<div>

				<div class="row">
					<div class="col-md-8 col-6">
						<h6 class="color-1" v-if="voters.length>0">{{election_title}} VOTERS</h6>
				<p v-if="voters.length>0">Total : {{voters.length}}</p>
					</div>
					<div class="col-md-4 col-6" style="text-align:right;">
						<button v-if="voters.length>0" class="btn btn-2" :disabled="disabled2" @click="send_voting_links()"> <label v-if="disabled2" class="spinner-border spinner-border-sm" role="status">
  <span class="visually-hidden">Loading...</span>
</label> {{link_text}}</button>
					</div>
				</div>

				
				<div class="table-responsive">
				<table class="table" v-if="voters.length>0">
					<thead>
						<tr><th>Name</th> <th>Membership number</th> <th>Email</th> <th>Phone</th> <th>Gender</th> <th>Action...</th> </tr>
					</thead>
					<tbody v-for="voter in voters">
					<tr> <td> {{voter.title}}.{{voter.first_name}} {{voter.last_name}} </td> <td> {{voter.membership_number}}</td> <td> {{voter.email}} </td> <td> {{voter.phone}} </td> <td> {{voter.gender}} </td> <td> <button class="btn" @click="voter_details(voter.id)"> <i class="bi bi-three-dots"></i> </button> </td> </tr>
					</tbody>
				</table>

				<div class="spinner-border color-1" role="status" v-if="show_loader">
  <span class="visually-hidden">Loading...</span>
</div>
				
			</div>

				

			</div>

		</div>


			</div>
			
			
		</div>

	</div>




	<!-- candidate details -->


	<div style="position: fixed; right: 0; top: 0; width: 100%; background-color: #fff; height: 100%;" class="shadow-lg rounded p-3" v-if="show_modal">

		<div class="row">
			
			<div class="col-md-6">
				

		<div style="padding-bottom:10px;" class="row">
			<div class="col-md-8 col-8">
				<h5 class="color-1">Candidate details</h5>
			</div>
			<div class="col-md-4 col-4" style="text-align:right;">
				<button class="btn" @click="show_modal=false"> <i class="bi bi-x-lg text-danger"></i> </button>
			</div>
		</div>

			
		<form @submit.prevent="edit_voter()">
		<div class="row">

			<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="election" aria-label="Floating label select example">
    <option v-for="season in seasons" :value="season.id">{{season.name}}</option>
  </select>
  <label for="floatingSelect">Select election</label>
</div>
				</div>


			<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="title" aria-label="Floating label select example">
    <option selected>Mr</option>
    <option>Miss</option>
    <option>Mrs</option>
    <option>Dr</option>
    <option>Prof</option>
  </select>
  <label for="floatingSelect">Select title</label>
</div>
				</div>

			


			<div class="col-md-6">

				<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Username" v-model="first_name">
  <label for="floatingInput">First name</label>
</div>
				
			</div>



	<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Last name" v-model="last_name">
  <label for="floatingPassword">Last name</label>
</div>	
</div>

<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="gender" aria-label="Floating label select example">
    <option selected>Male</option>
    <option>Female</option>
    <option>Other</option>
  </select>
  <label for="floatingSelect">Gender</label>
</div>
				</div>

<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Membership number" v-model="membership_number">
  <label for="floatingPassword">Membership number</label>
</div>	
</div>

<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="email" class="form-control" placeholder="Email" v-model="email">
  <label for="floatingPassword">Email</label>
</div>	
</div>

<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Phone" v-model="phone">
  <label for="floatingPassword">Phone</label>
</div>	
</div>


		</div>
<div style="padding-top: 20px;">
	<button class="btn btn-2" :disabled="disabled" >{{text}}</button>
</div>
						</form>




<div style="padding-top:40px;">

	<div>
		<input class="form-control" type="text" v-on:focus="$event.target.select()" ref="link" readonly :value="voting_link" disabled>
	</div>

	<button class="btn color-1" @click="copy_link()"> <i class="bi bi-layers-fill"></i> Copy voting link</button>
	<button class="btn text-danger" @click="delete_voter(id)"> <i class="bi bi-x-circle"></i> Delete</button>
</div>	


			</div>
			
			
		</div>
	</div>
	

	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'

	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				seasons : [],
				election : '',
				voters : [],
				election_title : '',
				show_modal : false,
				membership_number : '',
				first_name : '',
				last_name : '',
				email : '',
				phone : '',
				gender : '',
				text : 'Save changes',
				disabled : false,
				id : '',
				show_loader : false,
				link_text : 'Send voting links',
				disabled2 : false,
				voting_link : '',
				election_hash : ''

			}
		},
		methods : {

			copy_link(){
				this.$refs.link.focus();
      	document.execCommand('copy');
			},

			async send_voting_links(){
				this.link_text = 'Sending...'
				this.disabled2 = true
				const res = await axios.post(this.$store.state.api_url+'api/send-voting-links',{
					election_id : this.election
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				toast.success("Voting links sent to voters", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
				this.link_text = 'Send voting links'
				this.disabled2 = false
			},

			async delete_voter(id){
				const res = await axios.post(this.$store.state.api_url+'api/delete-voter',{
					id : id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_voters()
				this.show_modal = false
			},

			async edit_voter(){

				if(!this.election){
		toast.error("Election is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.title){
		toast.error("Title is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.first_name){
		toast.error("First name is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.last_name){
		toast.error("Last name is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

								if(!this.email){
		toast.error("Email is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}


								if(!this.phone){
		toast.error("Phone is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.membership_number){
		toast.error("Membership number is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.gender){
		toast.error("Gender is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.api_url+'api/edit-voter',{
					membership_number : this.membership_number,
					title : this.title,
					first_name : this.first_name,
					last_name :this.last_name,
					email : this.email,
					phone : this.phone,
					gender : this.gender,
					election : this.election,
					id : this.id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.text = 'Save changes'
				this.disabled = false
					toast.success("Changes saved", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
			this.show_modal = false
			this.get_voters()
			},

			async voter_details(id){
				this.id = id
				const res = await axios.get(this.$store.state.api_url+'api/voter-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.membership_number = data.membership_number
					this.title = data.title 
					this.first_name = data.first_name
					this.last_name = data.last_name
					this.email = data.email
					this.phone = data.phone
					this.gender = data.gender

				})
				this.voting_link = this.$store.state.voting_link+this.election_hash+'/'+this.id
				this.show_modal = true
			},

			async get_seasons(){
		const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.seasons = res
			},

			async get_voters(){
				this.show_loader = true
				const res = await axios.get(this.$store.state.api_url+'api/get-election-voters/'+this.election).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				}) 
				this.show_loader = false
				this.season_details(this.election)
				this.voters = res
			},

						async season_details(id){
				const res = await axios.get(this.$store.state.api_url+'api/season-details/'+id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.election_title = data.name
					this.election_hash = data.season_hash
				})
	
			},




		},
		created(){
			this.get_seasons()
		}
	}
</script>