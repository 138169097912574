<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:20px;">

		
			<div class="col-md-12">

				<div class="row">
					<div class="col-md-6 col-8">
						<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>
					</div>

					<div class="col-md-6 col-4" style="text-align:right;">
						<button class="btn btn-1" v-print="'#page_2'"> <i class="bi bi-printer"></i> Print</button>
					</div>
					
				</div>

		<div>
			<h5 class="color-1">List voters</h5>
		</div>

	


		<div style="padding-top:20px;" id="page_2">
			
			<div>

				
				<div class="table-responsive">
				<table class="table">
					<thead>
						<tr><th>Name</th> <th>Membership number</th> <th>Email</th> <th>Phone</th> <th>Gender</th>  </tr>
					</thead>
					<tbody v-for="voter in voters">
					<tr> <td> {{voter.title}}.{{voter.first_name}} {{voter.last_name}} </td> <td> {{voter.membership_number}}</td> <td> {{voter.email}} </td> <td> {{voter.phone}} </td> <td> {{voter.gender}} </td>  </tr>
					</tbody>
				</table>

				<div class="spinner-border color-1" role="status" v-if="show_loader">
  <span class="visually-hidden">Loading...</span>
</div>
				
			</div>

				

			</div>

		</div>


			</div>
			
			
		</div>

	</div>
	

	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'

	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				voters : [],
                upload_number : '',
                show_loader : false
			}
		},
		methods : {

            async get_voters(){
                this.show_loader = true
                const res = await axios.get(this.$store.state.api_url+'api/get-uploaded-voters/'+this.upload_number).then(function(response){
                    return response.data 
                }).catch(function(error){
                    console.log(error)
                })
                
                this.voters = res
                this.show_loader = false
            }

		},
		created(){
            this.upload_number = this.$route.params.upload_number
			this.get_voters()
		}
	}
</script>