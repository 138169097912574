<template>
	<nav class="navbar navbar-expand-lg navbar-ligh bg-ligh">
  <div class="container-fluid">
    <router-link to="/dashboard" class="navbar-brand color-1">KOGS</router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/dashboard" class="nav-link active" aria-current="page">Dashboard</router-link>
        </li>

        <li class="nav-item dropdown">
          <router-link to="" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Seasons
          </router-link>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><router-link to="/new-season" class="nav-link"> <i class="bi bi-plus icon"></i> New election</router-link></li>
            <li><router-link to="/election-seasons" class="nav-link"> <i class="bi bi-grid icon"></i> List elections</router-link></li>
            
          </ul>
        </li>


                <li class="nav-item dropdown">
          <router-link to="" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Positions
          </router-link>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><router-link to="/new-position" class="nav-link"> <i class="bi bi-plus icon"></i> New position</router-link></li>
            <li><router-link to="/list-positions" class="nav-link"> <i class="bi bi-grid icon"></i> List positions</router-link></li>
            
          </ul>
        </li>

          <li class="nav-item dropdown">
          <router-link to="" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Candidates
          </router-link>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><router-link to="/new-candidate" class="nav-link"> <i class="bi bi-plus icon"></i> New candidate</router-link></li>
            <li><router-link to="/list-candidates" class="nav-link"> <i class="bi bi-grid icon"></i> List candidates</router-link></li>
  
          </ul>
        </li>

         <li class="nav-item dropdown">
          <router-link to="" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Voters
          </router-link>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><router-link to="/new-voter" class="nav-link"> <i class="bi bi-plus icon"></i> New voter</router-link></li>
            <li><router-link to="/list-voters" class="nav-link"> <i class="bi bi-grid icon"></i> List voters</router-link></li>
              <li><hr class="dropdown-divider"></li>
              <li><router-link to="/upload-voters" class="nav-link"> <i class="bi bi-cloud-arrow-up icon"></i> Upload list</router-link></li>

              <li><router-link to="/list-uploads" class="nav-link"> <i class="bi bi-list icon"></i> List uploads</router-link></li>
            
          </ul>
        </li>

         <li class="nav-item">
          <router-link to="/messages" class="nav-link"> Messages</router-link>
        </li>

           <li class="nav-item">
          <router-link to="/election-results" class="nav-link"> Election results</router-link>
        </li>
       
      </ul>
      <div class="d-flex">
        <router-link to="/profile" class="btn" v-if="this.$store.state.logged_in"> <i class="bi bi-person-circle icon"></i> {{this.$store.state.username}} </router-link>
        <button class="btn btn-2" @click="logout()"> <i class="bi bi-power"></i> Log Out</button>
      </div>
    </div>
  </div>
</nav>
</template>


<script>
  
  export default{
    name : 'header',
    methods : {
      logout(){
        this.$store.state.logged_in = false
        localStorage.setItem('logged_in',false)
        this.$router.push('/')
      }
    }
  }

</script>