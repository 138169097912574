<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:70px;">

		
			<div class="col-md-12">

				<div class="row">
					<div class="col-md-6 col-8">
						<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>
					</div>

					<div class="col-md-6 col-4" style="text-align:right;">
						<button class="btn btn-1" v-print="'#page_2'"> <i class="bi bi-printer"></i> Print</button>
					</div>
					
				</div>

				

			<div class="table-responsive" id="page_2">

		<div>
			<h5 class="color-1">Elections</h5>
		</div>
			<table class="table">
				<thead>
					<tr> <th>Upload number</th> <th>Election</th> <th>Created</th> <th>Action...</th> </tr>
				</thead>

                <tbody v-for="upload in uploads">
                    <tr> <td> <router-link :to=" { name : 'view-upload-list', params : { 'upload_number' : upload.upload_number } } "> {{ upload.upload_number }}</router-link> </td> <td> {{ upload.name }} </td> <td> {{ upload.created }} </td> <td> <button class="btn text-danger" @click="delete_upload(upload.upload_number)"> <i class="bi bi-x-circle"></i> </button> </td> </tr>
                </tbody>
				
			</table>
			<div class="spinner-border color-1" role="status" v-if="show_loader">
  <span class="visually-hidden">Loading...</span>
</div>
			</div>

			</div>
		
			
		</div>

	</div>

	<Footer />



</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'
	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				uploads : [],
				show_loader : false

			}
		},
		methods : {

			async get_uploads(){
				this.show_loader = true
		const res = await axios.get(this.$store.state.api_url+'api/get-uploads').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.show_loader = false
		this.uploads = res
			},

            async delete_upload(upload_number){
		const res = await axios.post(this.$store.state.api_url+'api/delete-voter-upload',{
            upload_number : upload_number
        }).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})

        this.get_uploads()
		
			},


		},

		created(){
			this.get_uploads()
		}
	}
</script>