<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:70px;">

			
			<div class="col-md-6">

				<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>

		<div>
			<h5 class="color-1">New candidate</h5>
		</div>

			<form @submit.prevent="new_candidate()" enctype="multipart/form-data">
		<div class="row">

			<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="position" aria-label="Floating label select example">
    <option v-for="position in positions" :value="position.id">{{position.title}} - {{position.election_name}}</option>
  </select>
  <label for="floatingSelect">Select position</label>
</div>
				</div>

				<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="title" aria-label="Floating label select example">
    <option selected>Mr</option>
    <option>Miss</option>
    <option>Mrs</option>
    <option>Dr</option>
    <option>Prof</option>
  </select>
  <label for="floatingSelect">Select title</label>
</div>
				</div>


			<div class="col-md-6">
				<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="First name" v-model="first_name">
  <label for="floatingInput">First name</label>
</div>
				</div>



	<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Last name" v-model="last_name">
  <label for="floatingPassword">Last name</label>
</div>	
</div>


				<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="gender" aria-label="Floating label select example">
    <option selected>Male</option>
    <option>Female</option>
    <option>Other</option>
  </select>
  <label for="floatingSelect">Gender</label>
</div>
				</div>



<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Membership number" v-model="membership_number">
  <label for="floatingPassword">Membership number</label>
</div>	
</div>

<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="email" class="form-control" placeholder="Email" v-model="email">
  <label for="floatingPassword">Email</label>
</div>	
</div>

<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Phone" v-model="phone">
  <label for="floatingPassword">Phone number</label>
</div>	
</div>

<div class="col-md-12">
			<div class="form-floating mt-3">
  <input type="file" class="form-control" placeholder="Photo" @change="get_photo">
  <label for="floatingPassword">Photo</label>
</div>	
</div>


		</div>
<div style="padding-top: 20px;">
	<button class="btn btn-2" :disabled="disabled" >{{text}}</button>
</div>
						</form>

			</div>

			<div class="col-md-6" style="background-image:url('/assets/images/5362176.jpg'); background-size: contain; background-position:center; background-repeat: no-repeat;">
			
		</div>
			
			
		</div>

	</div>

	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'
	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				title : 'Mr',
				first_name : '',
				last_name : '',
				gender : 'Male',
				membership_number : '',
				email : '',
				phone : '',
				text : 'Save candidate',
				disabled : false,
				positions : [],
				position : '',
				photo : ''
			
			}
		},
		methods : {
			get_photo(e){
            this.photo = e.target.files[0]         
        },

			async new_candidate(){
				if(!this.position){
		toast.error("Position is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.title){
		toast.error("Title is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.first_name){
		toast.error("First name required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.last_name){
		toast.error("Last name required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.gender){
		toast.error("Gender is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.membership_number){
		toast.error("Membership is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.email){
		toast.error("Email is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.phone){
		toast.error("Phone is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.photo){
		toast.error("Photo  required!", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}


        	const config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }


		this.text = 'Please wait...'
		this.disabled = true
		const res = await axios.post(this.$store.state.api_url+'api/new-candidate',{
			title : this.title,
			first_name : this.first_name,
			last_name : this.last_name,
			gender : this.gender,
			membership_number : this.membership_number,
			email : this.email,
			phone : this.phone,
			position : this.position,
			photo : this.photo
		},config).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})

		this.title = ''
		this.first_name = ''
		this.last_name = ''
		this.gender = ''
		this.membership_number = ''
		this.email = ''
		this.phone = ''
		toast.success("Candidate added to list", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
	this.disabled = false
	this.text = 'Save candidate'

			},


			async get_positions(){
		const res = await axios.get(this.$store.state.api_url+'api/get-positions').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.positions = res
			},





		},

		created(){
			this.get_positions()
		}
	}
</script>