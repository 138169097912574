<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:70px;">

			
			<div class="col-md-6">

				<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>

		<div>
			<h5 class="color-1">New voter</h5>
		</div>

			<form @submit.prevent="new_voter()">
		<div class="row">

			<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="election" aria-label="Floating label select example">
    <option v-for="season in seasons" :value="season.id">{{season.name}}</option>
  </select>
  <label for="floatingSelect">Select election</label>
</div>
				</div>


			<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="title" aria-label="Floating label select example">
    <option selected>Mr</option>
    <option>Miss</option>
    <option>Mrs</option>
    <option>Dr</option>
    <option>Prof</option>
  </select>
  <label for="floatingSelect">Select title</label>
</div>
				</div>

			


			<div class="col-md-6">

				<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Username" v-model="first_name">
  <label for="floatingInput">First name</label>
</div>
				
			</div>



	<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Last name" v-model="last_name">
  <label for="floatingPassword">Last name</label>
</div>	
</div>

<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="gender" aria-label="Floating label select example">
    <option selected>Male</option>
    <option>Female</option>
    <option>Other</option>
  </select>
  <label for="floatingSelect">Gender</label>
</div>
				</div>

<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Membership number" v-model="membership_number">
  <label for="floatingPassword">Membership number</label>
</div>	
</div>

<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="email" class="form-control" placeholder="Email" v-model="email">
  <label for="floatingPassword">Email</label>
</div>	
</div>

<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Phone" v-model="phone">
  <label for="floatingPassword">Phone</label>
</div>	
</div>


		</div>
<div style="padding-top: 20px;">
	<button class="btn btn-2" :disabled="disabled" >{{text}}</button>
</div>
						</form>

			</div>

			<div class="col-md-6" style="background-image:url('/assets/images/5362176.jpg'); background-size: contain; background-position:center; background-repeat: no-repeat;">
			
		</div>
			
			
		</div>

	</div>

	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'
	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				title : '',
				election : '',
				text : 'Save voter',
				disabled : false,
				first_name : '',
				seasons : [],
				last_name : '',
				email : '',
				phone : '',
				membership_number : '',
				gender : ''
			}
		},
		methods : {
			async new_voter(){

				if(!this.election){
		toast.error("Election is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.title){
		toast.error("Title is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.first_name){
		toast.error("First name is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.last_name){
		toast.error("Last name is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

								if(!this.email){
		toast.error("Email is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}


								if(!this.phone){
		toast.error("Phone is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.membership_number){
		toast.error("Membership number is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.gender){
		toast.error("Gender is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}


		this.text = 'Please wait...'
		this.disabled = true
		const res = await axios.post(this.$store.state.api_url+'api/new-voter',{
			title : this.title,
			membership_number : this.membership_number,
			phone : this.phone,
			election : this.election,
			email : this.email,
			first_name : this.first_name,
			last_name : this.last_name,
			gender : this.gender
		}).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})

		if(res=='same number'){
			toast.error("A voter with this membership number already exists in this election", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		}else if(res=='same email'){
			toast.error("A voter with this email already exists in this election", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })

		}else if(res=='same phone'){
			toast.error("A voter with this phone number already exists in this election", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		}else if(res=='success'){
					this.title = ''
		this.election = ''
		this.membership_number = ''
		this.first_name = ''
		this.last_name = ''
		this.email = ''
		this.phone = ''
		this.gender = ''
		toast.success("Voter added to list", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })
		}else{
			toast.error("Something went wrong!", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })
		}

	this.disabled = false
	this.text = 'Save voter'

			},

			async get_seasons(){
		const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.seasons = res
			}
		},
		created(){
			this.get_seasons()
		}
	}
</script>