<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:70px;">

			
			<div class="col-md-6">

				<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>

		<div>
			<h5 class="color-1">Upload voters</h5>
		</div>

			<form @submit.prevent="upload_voters()" enctype="multipart/form-data">
		<div class="row">

			<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="election" aria-label="Floating label select example">
    <option v-for="season in seasons" :value="season.id">{{season.name}}</option>
  </select>
  <label for="floatingSelect">Select election</label>
</div>
				</div>


			

			


			<div class="col-md-6">

				<div class="form-floating mt-3">
  <input type="file" class="form-control" placeholder="Excel file" @change="get_list">
  <label for="floatingInput">Excel file</label>
</div>
				
			</div>



		</div>
<div style="padding-top: 20px;">
	<button class="btn btn-2" :disabled="disabled" >{{text}}</button>
</div>
						</form>

			</div>

			<div class="col-md-6" style="background-image:url('/assets/images/5362176.jpg'); background-size: contain; background-position:center; background-repeat: no-repeat;">
			
		</div>
			
			
		</div>

	</div>

	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'
	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				election : '',
				text : 'Upload list',
				disabled : false,
				seasons : [],
				list : ''
			}
		},
		methods : {
				get_list(e){
            this.list = e.target.files[0]         
        },

			async upload_voters(){

				if(!this.election){
		toast.error("Election is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

					if(!this.list){
		toast.error("List is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

					const config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }



		this.text = 'Please wait...'
		this.disabled = true
		const res = await axios.post(this.$store.state.api_url+'api/upload-voters',{
			election_id : this.election,
			list : this.list
		},config).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})

	this.disabled = false
	this.text = 'Upload list'
	this.list = ''
	this.election = ''

	toast.success("List uploaded", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })

			},

			async get_seasons(){
		const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.seasons = res
			}
		},
		created(){
			this.get_seasons()
		}
	}
</script>