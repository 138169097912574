import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login'
import Dashboard from '../components/Dashboard'
import NewSeason from '../components/NewSeason'
import ElectionSeasons from '../components/ElectionSeasons'
import NewPosition from '../components/NewPosition'
import Positions from '../components/Positions'
import NewCandidate from '../components/NewCandidate'
import ListCandidates from '../components/ListCandidates'
import NewVoter from '../components/NewVoter'
import ListVoters from '../components/ListVoters'
import BallotBox from '../components/BallotBox'
import ElectionResults from '../components/ElectionResults'
import UploadVoters from '../components/UploadVoters'
import ListUploads from '../components/ListUploads'
import ViewUploadList from '../components/ViewUploadList'
import ForgotPassword from '../components/ForgotPassword'
import Profile from '../components/Profile'
import Verify from '../components/Verify'
import Messages from '../components/Messages'


const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },

   {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },

    {
    path: '/new-season',
    name: 'new-season',
    component: NewSeason
  },
   {
    path: '/election-seasons',
    name: 'election-seasons',
    component: ElectionSeasons
  },
   {
    path: '/new-position',
    name: 'new-position',
    component: NewPosition
  },
   {
    path: '/list-positions',
    name: 'list-positions',
    component: Positions
  },

  {
      path: '/new-candidate',
    name: 'new-candidate',
    component: NewCandidate
  },


  {
      path: '/list-candidates',
    name: 'list-candidates',
    component: ListCandidates
  },

    {
      path: '/new-voter',
    name: 'new-voter',
    component: NewVoter
  },

      {
      path: '/list-voters',
    name: 'list-voters',
    component: ListVoters
  },

        {
      path: '/ballot-box/:election_hash/:voter_id',
    name: 'ballot-box',
    component: BallotBox
  },

          {
      path: '/election-results',
    name: 'election-results',
    component: ElectionResults
  },

    {
      path: '/upload-voters',
    name: 'upload-voters',
    component: UploadVoters
  },

  {
    path: '/list-uploads',
  name: 'list-uploads',
  component: ListUploads
},

{
  path: '/view-upload-list/:upload_number',
name: 'view-upload-list',
component: ViewUploadList
},

{
  path: '/forgot-password',
name: 'forgot-password',
component: ForgotPassword
},


{
  path: '/profile',
name: 'profile',
component: Profile
},


{
  path: '/verify',
name: 'verify',
component: Verify
},


{
  path: '/messages',
name: 'messages',
component: Messages
},

  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
