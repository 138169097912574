<template>

	<div class="container">
		<Header />


		<div>
			<h3>KOGS</h3>
			<h6>Online Voting Management System</h6>
			<p class="color-1">You are logged in as admin</p>
		</div>

		<div class="row" style="padding-top:70px;">
			
			<div class="col-md-3 mt-3">
					<div style="border:solid 1px #FFF0DC; padding:10px; border-radius:10px;" class="shadow">
						<div class="row">
						<div class="col-md-8 col-8">
							<h4 class="color-1"> {{ seasons.length }} </h4>
							<p>Elections</p>
						</div>
						<div class="col-md-4 col-4" style="text-align:right;">
							<h1> <i class="bi bi-grid color-1"></i> </h1>
						</div>
					</div>

					<div style="border-top: solid 1px #FFF0DC;">
						<router-link class="btn" to="/election-seasons"> <i class="bi bi-eye color-1"></i> View elections</router-link>
					</div>
						
					</div>
	
				</div>



	
				<div class="col-md-3 mt-3">
					<div style="border:solid 1px #FFF0DC; padding:10px; border-radius:10px;" class="shadow">
						<div class="row">
						<div class="col-md-8 col-8">
							<h4 class="color-1">{{ positions.length }}</h4>
							<p>Positions</p>
						</div>
						<div class="col-md-4 col-4" style="text-align:right;">
							<h1> <i class="bi bi-grid color-1"></i> </h1>
						</div>
					</div>

					<div style="border-top: solid 1px #FFF0DC;">
						<router-link class="btn" to="/list-positions"> <i class="bi bi-eye color-1"></i> View positions</router-link>
					</div>
						
					</div>
	
				</div>




				<div class="col-md-3 mt-3">
					<div style="border:solid 1px #FFF0DC; padding:10px; border-radius:10px;" class="shadow">
						<div class="row">
						<div class="col-md-8 col-8">
							<h4 class="color-1">{{ candidates.length }}</h4>
							<p>Candidates</p>
						</div>
						<div class="col-md-4 col-4" style="text-align:right;">
							<h1> <i class="bi bi-grid color-1"></i> </h1>
						</div>
					</div>

					<div style="border-top: solid 1px #FFF0DC;">
						<router-link class="btn" to="/list-candidates"> <i class="bi bi-eye color-1"></i> View candidates</router-link>
					</div>
						
					</div>
	
				</div>



				<div class="col-md-3 mt-3">
					<div style="border:solid 1px #FFF0DC; padding:10px; border-radius:10px;" class="shadow">
						<div class="row">
						<div class="col-md-8 col-8">
							<h4 class="color-1">{{ voters.length }}</h4>
							<p>Voters</p>
						</div>
						<div class="col-md-4 col-4" style="text-align:right;">
							<h1> <i class="bi bi-grid color-1"></i> </h1>
						</div>
					</div>

					<div style="border-top: solid 1px #FFF0DC;">
						<router-link class="btn" to="/list-voters"> <i class="bi bi-eye color-1"></i> View voters</router-link>
					</div>
						
					</div>
	
				</div>
	
		</div>



<div class="row" style="padding-top:100px;">
	<div class="col-md-3"></div>
<div class="col-md-6">

	<h4>  Election calendar <i class="bi bi-calendar-check icon"></i> </h4>

	<div>
		<div v-for="season in seasons" class="shadow m-3" style="border:solid 1px #FFF0DC; padding:10px; border-radius:10px;">
		<p class="color-1"> {{season.name}} </p>
		<p> {{season.start_date}} {{season.start_time}}  <span class="color-1">-</span> {{season.end_date}} {{season.end_time}} </p>			
		</div>
	</div>

	

</div>
<div class="col-md-3"></div>
</div>




	</div>

	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import axios from 'axios'
	import Footer from './layouts/Footer'
	export default{
		name : 'dashboard',
		components : {Header,Footer},

		data(){
			return{
				seasons : [],
				positions : [],
				candidates : [],
				voters : []
			}
		},

		methods : {

			async get_voters(){
		const res = await axios.get(this.$store.state.api_url+'api/all-voters').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.voters = res
			},

			async get_candidates(){
				this.show_loader = true
		const res = await axios.get(this.$store.state.api_url+'api/all-candidates').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.candidates = res
			},

			async get_seasons(){
				this.show_loader = true
		const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.seasons = res
			},

			async get_positions(){
				this.show_loader = true
		const res = await axios.get(this.$store.state.api_url+'api/get-positions').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.positions = res
	
			},

		},
		created(){
			this.get_seasons()
			this.get_positions()
			this.get_candidates()
			this.get_voters()
		
		}
	}
</script>