<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:20px;">

		
			<div class="col-md-12">

			<div class="row">
					<div class="col-md-6 col-8">
						<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>
					</div>

					<div class="col-md-6 col-4" style="text-align:right;">
						<button class="btn btn-1" v-print="'#page_2'"> <i class="bi bi-printer"></i> Print</button>
					</div>
					
				</div>

		<div>
			<h5 class="color-1">List candidates</h5>
		</div>

			<form>
		<div class="row">

			<div class="col-md-4">
				<div class="shadow-lg rounded">
					<div class="form-floating">
  <select class="form-select" v-model="election" aria-label="Floating label select example" @change="election_positions()">
    <option v-for="season in seasons" :value="season.id">{{season.name}}</option>
  </select>
  <label for="floatingSelect">Select election</label>
</div>
				</div>
				</div>
		</div>

						</form>


		<div style="padding-top:20px;" id="page_2">

			<h6 class="color-1">{{election_title}}</h6>
			
			<div v-for="position in positions">
				<h6 class="color-1">{{position.title}}</h6>
				<div class="table-responsive">
				<table class="table">
					<thead>
						<tr> <th>Photo</th> <th>Name</th> <th>Membership number</th> <th>Email</th> <th>Phone</th> <th>Gender</th> <th>Action...</th> </tr>
					</thead>
					<tbody v-for="candidate in candidates">
						<tr v-if="position.id==candidate.position_id"> <td> <img :src="this.$store.state.api_url+'assets/candidates/'+candidate.photo" style="height:40px; width: 40px;" class="shadow-lg rounded"> </td> <td>{{candidate.title}} . {{candidate.first_name}} {{candidate.last_name}} </td> <td> {{candidate.membership_number}} </td> <td> {{candidate.email}} </td> <td> {{candidate.phone}} </td> <td> {{candidate.gender}} </td> <td> <button class="btn" @click="candidate_details(candidate.id)"> <i class="bi bi-three-dots"></i> </button> </td> </tr>
					</tbody>
				</table>
				<div class="spinner-border color-1" role="status" v-if="show_loader">
  <span class="visually-hidden">Loading...</span>
</div>
			</div>

				<div>
					
				

				</div>

			</div>

		</div>


			</div>
			
			
		</div>

	</div>


	<!-- candidate details -->


	<div style="position: fixed; right: 0; top: 0; width: 100%; background-color: #fff; height: 100%;" class="shadow-lg rounded p-3" v-if="show_modal">

		<div class="row">
			
			<div class="col-md-6">
				

		<div style="padding-bottom:10px;" class="row">
			<div class="col-md-8 col-8">
				<h5 class="color-1">Candidate details</h5>
			</div>
			<div class="col-md-4 col-4" style="text-align:right;">
				<button class="btn" @click="show_modal=false"> <i class="bi bi-x-lg text-danger"></i> </button>
			</div>
		</div>

			
			<form @submit.prevent="edit_candidate()" enctype="multipart/form-data">
		<div class="row">

			<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="position" aria-label="Floating label select example">
    <option v-for="position in positions_2" :value="position.id">{{position.title}} - {{position.election_name}}</option>
  </select>
  <label for="floatingSelect">Select position</label>
</div>
				</div>

				<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="title" aria-label="Floating label select example">
    <option selected>Mr</option>
    <option>Miss</option>
    <option>Mrs</option>
    <option>Dr</option>
    <option>Prof</option>
  </select>
  <label for="floatingSelect">Select title</label>
</div>
				</div>


			<div class="col-md-6">
				<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="First name" v-model="first_name">
  <label for="floatingInput">First name</label>
</div>
				</div>



	<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Last name" v-model="last_name">
  <label for="floatingPassword">Last name</label>
</div>	
</div>


				<div class="col-md-6">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="gender" aria-label="Floating label select example">
    <option selected>Male</option>
    <option>Female</option>
    <option>Other</option>
  </select>
  <label for="floatingSelect">Gender</label>
</div>
				</div>



<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Membership number" v-model="membership_number">
  <label for="floatingPassword">Membership number</label>
</div>	
</div>

<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="email" class="form-control" placeholder="Email" v-model="email">
  <label for="floatingPassword">Email</label>
</div>	
</div>

<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Phone" v-model="phone">
  <label for="floatingPassword">Phone number</label>
</div>	
</div>

<div class="col-md-12">
			<div class="form-floating mt-3">
  <input type="file" class="form-control" placeholder="Photo" @change="get_photo">
  <label for="floatingPassword">Photo</label>
</div>	
</div>


		</div>
<div style="padding-top: 20px;">
	<button class="btn btn-2" :disabled="disabled">{{text}}</button>
</div>
						</form>


<div style="padding-top:40px;">
	<button class="btn text-danger" @click="delete_candidate(id)">Delete</button>
</div>	

			</div>
			
			
		</div>
	</div>


	

	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'

	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				seasons : [],
				election : '',
				positions : [],
				candidates : [],
				details : [],
				title : '',
				first_name : '',
				last_name : '',
				membership_number : '',
				email : '',
				phone : '',
				gender : '',
				show_modal : false,
				position : '',
				positions_2 : [],
				text : 'Save changes',
				id : '',
				show_loader : false,
				election_title : '',
				photo : ''
			}
		},
		methods : {

			async season_details(){
				const res = await axios.get(this.$store.state.api_url+'api/season-details/'+this.election).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.election_title = data.name 
				})
	
			},

			async delete_candidate(id){
				const res = await axios.post(this.$store.state.api_url+'api/delete-candidate',{
					id : id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.all_candidates()
				this.show_modal = false
			},

			get_photo(e){
            this.photo = e.target.files[0]         
        },

			//edit candidate 
			async edit_candidate(){

				if(!this.position){
		toast.error("Position is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.title){
		toast.error("Title is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.first_name){
		toast.error("First name is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.last_name){
		toast.error("Last name is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.membership_number){
		toast.error("Membership number is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.email){
		toast.error("Email is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.phone){
		toast.error("Phone is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				const config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }

				const res = await axios.post(this.$store.state.api_url+'api/edit-candidate',{
					position : this.position,
					title : this.title,
					first_name : this.first_name,
					last_name : this.last_name,
					membership_number : this.membership_number,
					gender : this.gender,
					email : this.email,
					phone : this.phone,
					id : this.id,
					photo : this.photo 
				},config).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				toast.error("Changes saved", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
				this.all_candidates()
			},

				//to get the position of a specific candidate
				async get_positions(){
		const res = await axios.get(this.$store.state.api_url+'api/get-positions').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.positions_2 = res
			},

			//to get all the positions
			async election_positions(){
				this.show_loader = true
		const res = await axios.get(this.$store.state.api_url+'api/election-positions/'+this.election).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.show_loader = false
		this.positions = res
		this.season_details()
			},

			async get_seasons(){
		const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.seasons = res
			},

				async all_candidates(){
		const res = await axios.get(this.$store.state.api_url+'api/all-candidates').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.candidates = res
			},

					async candidate_details(id){
					this.id = id
		const res = await axios.post(this.$store.state.api_url+'api/candidate-details',{
			id : id
		}).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.details = res

		res.forEach((data)=>{
			this.title = data.title
			this.position = data.position_id
			this.first_name = data.first_name
			this.last_name = data.last_name
			this.email = data.email
			this.phone = data.phone
			this.gender = data.gender
			this.membership_number = data.membership_number
		})

		this.show_modal = true
			},



		},
		created(){
			this.get_seasons()
			this.all_candidates()
			this.get_positions()
		}
	}
</script>