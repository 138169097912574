import { createStore } from 'vuex'

export default createStore({
  state: {
    api_url : 'https://voteserver.kogs.or.ke/',
    username : localStorage.getItem('username'),
    logged_in : localStorage.getItem('logged_in'),
    voting_link : 'https://voting.kogs.or.ke/'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
