<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:70px;">

		
			<div class="col-md-12">

				<div class="row">
					<div class="col-md-6 col-8">
						<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>
					</div>

					<div class="col-md-6 col-4" style="text-align:right;">
						<button class="btn btn-1" v-print="'#page_2'"> <i class="bi bi-printer"></i> Print</button>
					</div>
					
				</div>

				

			<div class="table-responsive" id="page_2">

		<div>
			<h5 class="color-1">Elections</h5>
		</div>
			<table class="table">
				<thead>
					<tr> <th>Title</th> <th>Start date</th> <th>End date</th> <th>Status</th> <th>Description</th> <th>Action...</th> </tr>
				</thead>
				<tbody v-for="season in seasons">
					<tr>
						<td> {{season.name}} </td>
						<td> {{season.start_date}} {{season.start_time}}  </td>
						<td> {{season.end_date}} {{season.end_time}} </td>
						<td>
						 <p class="badge bg-success" v-if="season.status==1">Published</p> 
						 <p class="badge bg-danger" v-if="season.status==0">Not published</p>
						</td>
						<td>{{season.description}}</td>
						<td> <button class="btn" @click="season_details(season.id)"> <i class="bi bi-three-dots"></i> </button> </td>
					</tr>
				</tbody>
			</table>
			<div class="spinner-border color-1" role="status" v-if="show_loader">
  <span class="visually-hidden">Loading...</span>
</div>
			</div>

			</div>
		
			
		</div>

	</div>

	<Footer />


	<div style="position: fixed; right: 0; top: 0; width: 100%; background-color: #fff; height: 100%;" class="shadow-lg rounded p-3" v-if="show_modal">

		<div class="row">
			
			<div class="col-md-6">
				

		<div style="padding-bottom:20px;" class="row">
			<div class="col-md-8 col-8">
				<h5 class="color-1">Election details</h5>
				<p class="badge bg-success" v-if="status==1">Published</p> 
				<p class="badge bg-danger" v-if="status==0">Not published</p>
			</div>
			<div class="col-md-4 col-4" style="text-align:right;">
				<button class="btn" @click="show_modal=false"> <i class="bi bi-x-lg text-danger"></i> </button>
			</div>
		</div>

			<form @submit.prevent="edit_election()">
		<div class="row">
			<div class="col-md-12">

				<div class="form-floating">
  <input type="text" class="form-control" placeholder="Username" v-model="name">
  <label for="floatingInput">Title</label>
</div>
				
			</div>

			<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="date" class="form-control" placeholder="Start date" v-model="start_date">
  <label for="floatingPassword">Start date</label>
</div>	
</div>

		<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="time" class="form-control" placeholder="Start time" v-model="start_time">
  <label for="floatingPassword">Start time</label>
</div>	
</div>

		<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="date" class="form-control" placeholder="End date" v-model="end_date">
  <label for="floatingPassword">End date</label>
</div>	
</div>

		<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="time" class="form-control" placeholder="End time" v-model="end_time">
  <label for="floatingPassword">End time</label>
</div>	
</div>

	<div class="col-md-12">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Description" v-model="description">
  <label for="floatingPassword">Description</label>
</div>	
</div>


		</div>
<div style="padding-top: 20px;">
	<button class="btn btn-2" :disabled="disabled" >{{text}}</button>
</div>
</form>


<div style="padding-top:40px;">
	<button class="btn text-danger" @click="delete_election(id)">Delete</button>
	<button class="btn color-1" @click="publish_election(id)" v-if="status==0">Publish</button>
	<button class="btn color-1" @click="unpublish_election(id)" v-if="status==1">Unpublish</button>
</div>	

			</div>
		
			
		</div>


		

	</div>
	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'
	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				seasons : [],
				details : [],
				text : 'Save changes',
				disabled : false,
				name : '',
				start_date : '',
				start_time : '',
				end_date : '',
				end_time : '',
				description : '',
				status : '',
				show_modal : false,
				id : '',
				show_loader : false

			}
		},
		methods : {

			export_pdf(){
      

			},

			async unpublish_election(id){
				const res = await axios.post(this.$store.state.api_url+'api/unpublish-election',{
					id : id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				toast.success("Election unpublished", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
				this.get_seasons()
				this.show_modal = false
			},

			async publish_election(id){
				const res = await axios.post(this.$store.state.api_url+'api/publish-election',{
					id : id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				toast.success("Election published", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
				this.get_seasons()
				this.show_modal = false
			},

			async delete_election(id){
				const res = await axios.post(this.$store.state.api_url+'api/delete-election',{
					id : id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_seasons()
				this.show_modal = false
			},


			async get_seasons(){
				this.show_loader = true
		const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.show_loader = false
		this.seasons = res
			},

			async season_details(id){
				const res = await axios.get(this.$store.state.api_url+'api/season-details/'+id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.details = res
				res.forEach((data)=>{
					this.name = data.name 
					this.start_date = data.start_date
					this.start_time = data.start_time
					this.end_date = data.end_date
					this.end_time = data.end_time
					this.description = data.description
					this.status = data.status
					this.id = data.id
				})
				this.show_modal = true
			},

			async edit_election(){

				if(!this.name){
		toast.error("Title of election is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.start_date){
		toast.error("Start date is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}


		if(!this.start_time){
		toast.error("Start time is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

	if(!this.end_date){
		toast.error("End date is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}


	if(!this.end_time){
		toast.error("End time is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

		if(!this.description){
		toast.error("Description of election is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}
				this.text = 'Please wait...'
				this.disabled = true
		const res = await axios.post(this.$store.state.api_url+'api/edit-election',{
			name : this.name,
			start_date : this.start_date,
			start_time : this.start_time,
			end_date : this.end_date,
			end_time : this.end_time,
			description : this.description,
			id : this.id 
		}).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})

		this.name = ''
		this.start_date = ''
		this.start_time = ''
		this.end_date = ''
		this.end_time = ''
		this.description = ''
		toast.success("Changes saved", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
	this.disabled = false
	this.text = 'Save changes'
	this.show_modal = false
	this.get_seasons()

			}



		},

		created(){
			this.get_seasons()
			this.export_pdf()
		}
	}
</script>