<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:70px;">

			
			<div class="col-md-6">

				<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>

		<div>
			<h5 class="color-1">New season</h5>
		</div>

			<form @submit.prevent="new_season()">
		<div class="row">
			<div class="col-md-12">

				<div class="form-floating">
  <input type="text" class="form-control" placeholder="Username" v-model="name">
  <label for="floatingInput">Title</label>
</div>
				
			</div>

			<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="date" class="form-control" placeholder="Start date" v-model="start_date">
  <label for="floatingPassword">Start date</label>
</div>	
</div>

		<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="time" class="form-control" placeholder="Start time" v-model="start_time">
  <label for="floatingPassword">Start time</label>
</div>	
</div>

		<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="date" class="form-control" placeholder="End date" v-model="end_date">
  <label for="floatingPassword">End date</label>
</div>	
</div>

		<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="time" class="form-control" placeholder="End time" v-model="end_time">
  <label for="floatingPassword">End time</label>
</div>	
</div>

	<div class="col-md-12">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Description" v-model="description">
  <label for="floatingPassword">Description</label>
</div>	
</div>


		</div>
<div style="padding-top: 20px;">
	<button class="btn btn-2" :disabled="disabled" >{{text}}</button>
</div>
						</form>

			</div>

		<div class="col-md-6" style="background-image:url('/assets/images/5362176.jpg'); background-size: contain; background-position:center; background-repeat: no-repeat;">
			
		</div>
			
			
		</div>

	</div>

	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'
	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				name : '',
				start_date : '',
				start_time : '',
				end_date : '',
				end_time : '',
				description : '',
				text : 'Save election',
				disabled : false
			}
		},
		methods : {
			async new_season(){

				if(!this.name){
		toast.error("Title of election is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.start_date){
		toast.error("Start date is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}


		if(!this.start_time){
		toast.error("Start time is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

	if(!this.end_date){
		toast.error("End date is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}


	if(!this.end_time){
		toast.error("End time is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

		if(!this.description){
		toast.error("Description of election is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}
				this.text = 'Please wait...'
				this.disabled = true
		const res = await axios.post(this.$store.state.api_url+'api/new-season',{
			name : this.name,
			start_date : this.start_date,
			start_time : this.start_time,
			end_date : this.end_date,
			end_time : this.end_time,
			description : this.description
		}).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})

		this.name = ''
		this.start_date = ''
		this.start_time = ''
		this.end_date = ''
		this.end_time = ''
		this.description = ''
		toast.success("Election added to list", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
	this.disabled = false
	this.text = 'Save election'

			}
		}
	}
</script>