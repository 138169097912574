<template>

	<div class="container">
		<Header />

		<div style="padding-top:30px;">
			<h6>Admin profile</h6>
		</div>


		<div class="row">
			<div class="col-md-6">
				
				<div>
			
			<form @submit.prevent="change_password()">
				<div class="row">
					<div class="col-md-6 mt-3">
						<label>New password</label>
						<input type="password" v-model="password" class="form-control">
					</div>

					<div class="col-md-12 mt-3">
						<button class="btn btn-1">Change password</button>
					</div>

				</div>
			</form>

		</div>

			</div>
		</div>



		

	</div>



	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import axios from 'axios'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	export default{
		name : 'dashboard',
		components : {Header,Footer},

		data(){
			return{
				password : '',
				
			}
		},

		methods : {

			async change_password(){

			if(!this.password){
				toast.error("Enter password", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })
			return 
			}
			
		const res = await axios.post(this.$store.state.api_url+'api/change-password',{
			password : this.password
		}).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		toast.success("Changes saved successfully", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })
	
			},

		},
		
	}
</script>