<template>

	<div class="container">
		

		<div class="row" style="padding-top:70px;">

			<div class="col-md-2"></div>
			<div class="col-md-4" style="background-image: url('/assets/images/5362176.jpg'); background-size: contain; background-repeat: no-repeat; background-position: center; min-height: 100px;"> </div>
			<div class="col-md-4" style="text-align:center; padding-top:30px; border-left: solid 1px #FFCDF3;">
				<div>
					<h6>Welcome, <span class="color-1"> {{first_name}} {{last_name}} </span> </h6>
			<h5 class="color-1">{{election_title}}</h5>
			<p style="color:grey;"> <span>{{start_date}} : {{start_time}} </span> - <span>{{end_date}} : {{end_time}}</span> </p>
		</div>

		<div v-if="dates=='Date is valid'">
			<button class="btn btn-1" @click="check_voter()" v-if="voted=='not voted'">Open Ballot</button>
			<label class="message" v-if="voted=='voted'">
				 <i class="bi bi-info-circle icon"></i> <span class="color-1">You have voted in this election</span>
			</label>
		</div>

		<label class="message" v-if="dates!='Date is valid'">
				 <i class="bi bi-info-circle icon"></i> <span class="color-1">{{dates}}</span>
			</label>
			
			</div>
			<div class="col-md-2"></div>
		</div>

		<!-- ballot -->
		<div style="padding-top:20px; position: absolute; width:100%; height:100%; top:0; right:0; background-color:#fff; padding-left: 10px;" v-if="show_ballot">

			<div class="row">
				<div class="col-md-2"></div>
				<div class="col-md-8">
					<div class="row">
						<div class="col-md-10 col-10">
							<h5 class="color-1">{{election_title}}</h5>
						</div>
						<div class="col-md-2 col-2" style="text-align:right;">
						<button class="btn text-danger" @click="show_ballot=false"> <i class="bi bi-x-lg"></i> </button>	
						</div>
					</div>

			<div v-for="position in positions">
				
				<div style="padding-top:30px;">
					<h6 class="color-1">{{position.title}}</h6>
				</div>


					<div v-for="candidate in candidates" :key="candidate.id">
						
						<div v-if="position.id==candidate.position_id">
						<div class="row mt-3 design">
							<div class="col-md-2 col-3">

			<div :style="{width:'100%', height:'70px', backgroundSize:'cover', backgroundPosition:'top', backgroundImage:'url('+this.$store.state.api_url+'assets/candidates/'+candidate.photo+')' }" class="shadow-lg rounded"></div>
								
								
							</div>
							<div class="col-md-6 col-7 p-3">
							<h6> {{candidate.title}} . {{candidate.first_name}} {{candidate.last_name}} </h6>	
							</div>
							<div class="col-md-2 col-2 pt-3">
							<input type="radio" v-model="selected_candidates[position.id]" :value="candidate.id" class="title-style">
							</div>
						</div>
							
						</div>

					</div>
				

			
				<div class="spinner-border color-1" role="status" v-if="show_loader">
  <span class="visually-hidden">Loading...</span>
</div>


			</div>


			<div style="padding-top:30px; padding-bottom: 30px;">
				<button class="btn btn-1" @click="submit_vote()" :disabled="disabled"> <label v-if="disabled" class="spinner-border spinner-border-sm" role="status">
  <span class="visually-hidden">Loading...</span>
</label> {{text}}</button>
			</div>

			
					
				</div>
				<div class="col-md-2">
					
				</div>
				
			</div>
			

		</div>


		<div>
			
		</div>



	</div>

	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	export default{
		name : 'dashboard',
		components : {Header,Footer},

		data(){
			return {
				election_id : '',
				election_hash : '',
				election_title : '',
				candidates : [],
				positions : [],
				show_loader : false,
				start_date : '',
				start_time : '',
				end_date : '',
				end_time : '',
				show_ballot : false,
				selected_candidates : [],
				voter_id : '',
				text : 'Sumit vote',
				disabled : false,
				first_name : '',
				last_name : '',
				voted : '',
				dates : ''
			}
		},

		methods : {

			async check_voter(){
				const res = await axios.post(this.$store.state.api_url+'api/check-voter',{
					voter_id : this.voter_id,
					election_id : this.election_id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				if(res=='not voted'){
					this.open_ballot()
				}else{
					toast.error("You have voted in this election", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
				}
			},


			async submit_vote(){
				if(this.selected_candidates.length==0){
		toast.error("Your ballot is empty!", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
		}

		this.text = 'Please wait...'
		this.disabled = true

		const res = await axios.post(this.$store.state.api_url+'api/submit-vote',{
			voter_id : this.voter_id,
			election_hash : this.election_hash,
			selected_candidates : this.selected_candidates,
			election_id : this.election_id
		}).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})

		this.text = 'Submit vote'
		this.disabled = false
		this.show_ballot = false
		
	toast.success("Vote submitted successfully", {
        autoClose: 3000,
        theme : 'dark',
        position:'top-center'
      })
	
		this.check_if_voted()
			},


			open_ballot(){
				this.show_ballot=!this.show_ballot
				if(this.show_ballot){
					this.election_positions()
				}
			},

			async hashed_season_details(){
				const res = await axios.get(this.$store.state.api_url+'api/hashed-season-details/'+this.election_hash).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.election_title = data.name 
					this.election_id = data.id
					this.start_date = data.start_date
					this.start_time = data.start_time
					this.end_date = data.end_date
					this.end_time = data.end_time
				})
	
			},

				async all_candidates(){
		const res = await axios.get(this.$store.state.api_url+'api/all-candidates').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.candidates = res
			},

				//to get all the positions
			async election_positions(){
				this.show_loader = true
		const res = await axios.get(this.$store.state.api_url+'api/election-positions/'+this.election_id).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.show_loader = false

		this.positions = res
			},

			async voter_details(){
				const res = await axios.get(this.$store.state.api_url+'api/voter-details/'+this.voter_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{ 
					this.first_name = data.first_name
					this.last_name = data.last_name

				})
			},

			async check_if_voted(){
				const res = await axios.get(this.$store.state.api_url+'api/check-if-voted/'+this.voter_id+'/'+this.election_hash).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.voted = res
			},

			async check_dates(){
				const res = await axios.get(this.$store.state.api_url+'api/check-dates/'+this.election_hash).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.dates = res
			},

		},

		created(){
			this.election_hash = this.$route.params.election_hash
			this.voter_id = this.$route.params.voter_id
			this.check_dates()
			this.hashed_season_details()
			this.all_candidates()
			this.voter_details()
			this.check_if_voted()

		}

	}
</script>



<style>
	.design{
		border-bottom: dotted 1px #FFC3F0;
	}
	
</style>