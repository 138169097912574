<template>
	

	<section style="padding-top:100px;">
		
		<div class="container">
			<div class="row">
				<div class="col-md-2"></div>
				<div class="col-md-4" style="background-image: url('/assets/images/5362176.jpg'); background-size: contain; background-repeat: no-repeat; background-position: center; min-height: 100px;"></div>
				<div class="col-md-4" style="text-align: left; border-left: solid 1px #AED6F1;">
					
					<div>
						<h6 class="color-1">eVote</h6>
						<h6>Admin login</h6>
					</div>

					<div>
						<form @submit.prevent="login()">
		<div class="form-floating mb-3">
  <input type="text" class="form-control" placeholder="Username" v-model="username">
  <label for="floatingInput">Username</label>
</div>
<div class="form-floating">
  <input type="password" class="form-control" placeholder="Password" v-model="password">
  <label for="floatingPassword">Password</label>
</div>

<div style="padding-top: 20px;">
	<button class="btn btn-2">Login</button>
	<router-link to="/forgot-password" class="btn color-1">Forgot password</router-link>
</div>
						</form>
					</div>

				</div>
				<div class="col-md-2"></div>
			</div>
		</div>

	</section>

<Footer />

</template>


<script>
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	export default{
		name : 'login',
		components : {Footer},
		data(){
			return {
				username : '',
				password : ''
			}
		},
		methods : {
			async login(){
				if(!this.username){
						toast.error("Enter username", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
						return
				}

					if(!this.password){
						toast.error("Enter password", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
						return
				}

				const res = await axios.post(this.$store.state.api_url+'api/admin-login',{
					username : this.username,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length>0){
					res.forEach((data)=>{
						localStorage.setItem('username',data.username)
						this.$store.state.username = data.username
						localStorage.setItem('logged_in',true)
						this.$store.state.logged_in = true
					})
					this.$router.push('/dashboard')
				}else{
						toast.error("Wrong username or password", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
				}

			}
		}
	}
</script>