<template>
	

	<section style="padding-top:100px;">
		
		<div class="container">
			<div class="row">
				<div class="col-md-2"></div>
				<div class="col-md-4" style="background-image: url('/assets/images/5362176.jpg'); background-size: contain; background-repeat: no-repeat; background-position: center; min-height: 100px;"></div>
				<div class="col-md-4" style="text-align: left; border-left: solid 1px #AED6F1;">
					
					<div>
						<h6 class="color-1">eVote</h6>
						<h6>Reset password</h6>
					</div>

					<div>
						<form @submit.prevent="forgot_password()">
		<div class="form-floating mb-3">
  <input type="text" class="form-control" placeholder="Email address" v-model="email">
  <label for="floatingInput">Email address</label>
</div>


<div style="padding-top: 20px;">
	<button class="btn btn-2" :disabled="disabled">{{text}}</button>
	<router-link to="/" class="btn color-1"> <i class="bi bi-arrow-left"></i> Back to login</router-link>
</div>
						</form>
					</div>

				</div>
				<div class="col-md-2"></div>
			</div>
		</div>

	</section>

<Footer />

</template>


<script>
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	export default{
		name : 'login',
		components : {Footer},
		data(){
			return {
				email : '',
				text : 'Reset password',
				disabled : false
			
			}
		},
		methods : {

			async forgot_password(){
				if(!this.email){
						toast.error("Enter email", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
						return
				}

				this.text = 'Please wait...'
				this.disabled = true
				
				const res = await axios.post(this.$store.state.api_url+'api/reset-password',{
					email : this.email
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

			if(res=='success'){
				toast.success("A new password has been sent to your email address", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })
			}else{
			toast.error("Email does not exist", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })	
			}

			this.text = 'Reset password'
			this.disabled = false

			}


		}


	}
</script>