<template>

	<div class="container">
		<Header />

		<div class="row" style="padding-top:70px;">

			
			<div class="col-md-6">

				<div style="padding-bottom: 20px;">
					<router-link to="/dashboard" class="btn btn-2"> <i class="bi bi-arrow-left"></i> Back to dashboard</router-link>
				</div>

		<div>
			<h5 class="color-1">New position</h5>
		</div>

			<form @submit.prevent="new_position()">
		<div class="row">

			<div class="col-md-12">
				<div class="form-floating mt-3">
  <select class="form-select" v-model="election" aria-label="Floating label select example">
    <option v-for="season in seasons" :value="season.id">{{season.name}}</option>
  </select>
  <label for="floatingSelect">Select election</label>
</div>
				</div>

			<div class="col-md-6">

				<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Username" v-model="title">
  <label for="floatingInput">Title</label>
</div>
				
			</div>



	<div class="col-md-6">
			<div class="form-floating mt-3">
  <input type="number" min="1" class="form-control" placeholder="Priority" v-model="priority">
  <label for="floatingPassword">Priority</label>
</div>	
</div>

<div class="col-md-12">
			<div class="form-floating mt-3">
  <input type="text" class="form-control" placeholder="Description" v-model="description">
  <label for="floatingPassword">Description</label>
</div>	
</div>


		</div>
<div style="padding-top: 20px;">
	<button class="btn btn-2" :disabled="disabled" >{{text}}</button>
</div>
						</form>

			</div>

			<div class="col-md-6" style="background-image:url('/assets/images/5362176.jpg'); background-size: contain; background-position:center; background-repeat: no-repeat;">
			
		</div>
			
			
		</div>

	</div>

	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	import axios from 'axios'
	export default{
		name : 'dashboard',
		components : {Header,Footer},
		data (){
			return {
				title : '',
				description : '',
				text : 'Save position',
				disabled : false,
				priority : '',
				seasons : [],
				election : ''
			}
		},
		methods : {
			async new_position(){

				if(!this.election){
		toast.error("Election is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.title){
		toast.error("Title is required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.description){
		toast.error("Description required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}

				if(!this.priority){
		toast.error("Priority required", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
		return
				}


		this.text = 'Please wait...'
		this.disabled = true
		const res = await axios.post(this.$store.state.api_url+'api/new-position',{
			title : this.title,
			description : this.description,
			priority : this.priority,
			election : this.election
		}).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})

		this.title = ''
		this.description = ''
		this.priority = ''
		toast.success("Position added to list", {
        autoClose: 1000,
        theme : 'dark',
        position:'top-center'
      })
	this.disabled = false
	this.text = 'Save position'

			},
			async get_seasons(){
		const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		this.seasons = res
			}
		},
		created(){
			this.get_seasons()
		}
	}
</script>