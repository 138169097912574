<template>

	<div class="container">
		<Header />

	


		<div class="row">
			<div class="col-md-4"></div>
			

			<div class="col-md-4">

					<div style="padding-top:30px; text-align: left; ">
			<h6>Send message</h6>
		</div>

		

		
				
				<div>
			
			<form @submit.prevent="send_message()">
				<div class="row">

					<div class="col-md-12 mt-3">
						<select class="form-select" v-model="season">
							<option value="">Choose election</option>
							<option v-for="season in seasons" :value="season.id"> {{season.name}} </option>
						</select>
					</div>

					<div class="col-md-12 mt-3">
						<label>Message</label>
						<textarea class="form-control" v-model="message" rows="6"></textarea>
					</div>

					<div class="col-md-12 mt-3">
						<button class="btn btn-1" :disabled="disabled">{{text}}</button>
					</div>

				</div>
			</form>

		</div>

			</div>

			<div class="col-md-4"></div>

		</div>



		

	</div>



	<Footer />
	
</template>


<script>
	import Header from './layouts/Header'
	import axios from 'axios'
	import Footer from './layouts/Footer'
	import { toast } from 'vue3-toastify'
	import 'vue3-toastify/dist/index.css'
	export default{
		name : 'dashboard',
		components : {Header,Footer},

		data(){
			return{
				message : '',
				seasons : [],
				season : '',
				text : 'Send message',
				disabled : false
				
			}
		},

		methods : {

			async send_message(){

				if(!this.season){
				toast.error("Choose election", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })
			return 
			}

			if(!this.message){
				toast.error("Enter message", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })
			return 
			}

			this.text = 'Please wait...'
			this.disabled = true
			
		const res = await axios.post(this.$store.state.api_url+'api/send-message',{
			message : this.message,
			season : this.season
		}).then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		

		toast.success("Message sent successfully", {
        autoClose: 2000,
        theme : 'dark',
        position:'top-center'
      })

		this.text = 'Send message'
		this.disabled = false
		
		
	
			},


			async get_seasons(){
				this.show_loader = true
		const res = await axios.get(this.$store.state.api_url+'api/get-seasons').then(function(response){
			return response.data
		}).catch(function(error){
			console.log(error)
		})
		
		this.seasons = res
			},

		},
		created(){
			this.get_seasons()
		}
		
	}
</script>